<template>
  <div class="articleTB-four" :style="articleContainStyl" ref="articleContainerFour">
    <div class="more-box-top" :style="linkTopStyl" v-if="buttonContent.isShowButton">
      <a href="javascript:;" class="link-more" @click="checkMore">
        {{buttonContent.buttonName || '查看更多'}}
      </a>
    </div>
    <div class="article-inner" v-if="publishContent.showRows==2" :style="articleInnerStyl">
      <div
        v-for="(item, index) in articleDataList"
        :key="index"
        @click="skipLinks(item)"
        class="article-box"
        :style="articleBoxStyl"
        @mouseover="overArticleItem(item)"
      >
          <div style="position: relative" v-if="publishContent.includeItemsImageOrVideo">
            <div class="img" :class="`img${receiveProps.blockInstanceId}${index}`" :style="imageStyl"
          >
          <!-- <img v-if="item[showImgUrl]" :src="item[showImgUrl]"/> -->
          <!-- <img class="img-default" src="../../asset/images/default-img.jpg" alt="" v-if="!item[showImgUrl]"/> -->
          
        </div>
        <div class="video-overly" @click="skipLinks(item)" v-if="item[isFileType] == 2">
            <img src="../../asset/images/video-btn.png"
            >
          </div>
          </div>
        <div class="article" v-if="
        (publishContent.includeItemsTitle&&item[showMainTile])||(publishContent.includeItemsDes&&item[showMainDesc])">
          <div class="main-title" :style="mainTitle"
          v-if="publishContent.includeItemsTitle&&item[showMainTile]">
            {{item[showMainTile]}}
          </div>
          <div class="description" :style="descriptionTitle"
          v-if="publishContent.includeItemsDes&&item[showMainDesc]">
            {{item[showMainDesc]}}
          </div>
          <p :style="publishStyl" v-if="publishContent.showContentType == 2" class="publish-num">
            <span class="publish-time" v-if="publishContent.showPublishFlag">{{ item.publishTime | formatTime }}</span>
            <span class="obser-num" v-if="publishContent.showReadFlag"><img src="../../asset/images/obser-num-img.png" style="width: 14px;height: 14px"> {{item.readNum}}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="swiper-wrap" v-if="publishContent.showRows==1">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in articleDataList"
            :key="index"
            :style="swiperBoxStyl"
          >
            <div class="inner-swiper" @mouseover="overArticleItem(item)">
              <div style="position: relative" v-if="publishContent.includeItemsImageOrVideo">
                <div class="image-swiper" @click="skipLinks(item)" :class="`img${receiveProps.blockInstanceId}${index}`" :style="imageStyl">
                <!-- <img :src="item[showImgUrl]" v-if="item[showImgUrl]"/> -->
                <!-- <img src="../../asset/images/default-img.jpg" alt="" v-if="!item[showImgUrl]"/> -->
                
              </div>
              <div class="video-overly" @click="skipLinks(item)" v-if="item[isFileType] == 2">
            <img src="../../asset/images/video-btn.png"
            >
          </div>
              </div>
              <div class="article" v-if="
              (publishContent.includeItemsTitle&&item[showMainTile])||(publishContent.includeItemsDes&&item[showMainDesc])">
                <div class="main-title" :style="mainTitle" v-if="publishContent.includeItemsTitle&&item[showMainTile]">
                  {{item[showMainTile]}}
                </div>
                <div class="description" :style="descriptionTitle"
                v-if="publishContent.includeItemsDes&&item[showMainDesc]">
                  {{item[showMainDesc]}}
                </div>
                <p :style="publishStyl" v-if="publishContent.showContentType == 2" class="publish-num">
                  <span class="publish-time" v-if="publishContent.showPublishFlag">{{ item.publishTime | formatTime}}</span>
                  <span class="obser-num" v-if="publishContent.showReadFlag"><img src="../../asset/images/obser-num-img.png" style="width: 14px;height: 14px"> {{item.readNum}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Add Arrows -->
      <div class="button-box">
        <div class="swiper-button-next"><img src="../../asset/images/right-btn.png" alt=""></div>
        <div class="swiper-button-prev"><img src="../../asset/images/left-btn.png" alt=""></div>
      </div>
    </div>
    <div class="more-box-bottom" :style="linkBottomStyl" v-if="buttonContent.isShowButton">
      <a href="javascript:;" class="link-more-bottom"  @click="checkMore">
          {{buttonContent.buttonName || '查看更多'}}
      </a>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import {commonClickFn, getData, checkMoreClickFn} from './commonModuleFn'
export default {
  name: "articleLR",
  props: {
    receiveProps: {
      type: Object,
      default: () => {},
    }
  },
  filters: {
    formatTime(val) {
      return (
        val.split(" ")[0].split("-").join("/")
      );
    },
  },
  computed: {
    articleContainStyl() {
      let {blockMargeTop, blockMargeBottom, blockBGClolor} = this.receiveProps.tempData.specialStyle
      return {
        "--all-margin-top": `${blockMargeTop}px`,
        "--all-margin-bottom": `${blockMargeBottom}px`,
        "--all-background-color": blockBGClolor,
        "--all-box-width": this.isBanner? '1200px': '600px'
      };
    },
    linkTopStyl() {
      let { linkBtnAlign, linkBtnLocation } = this.receiveProps.tempData.specialStyle;
      return {
        "--link-text-align": linkBtnAlign,
        "--link-top-display": linkBtnLocation == 'top'?"block": 'none',
      };
    },
    linkBottomStyl() {
      let { linkBtnAlign, linkBtnLocation } = this.receiveProps.tempData.specialStyle;
      return {
        "--link-text-align": linkBtnAlign,
        "--link-bottom-display": linkBtnLocation=='top'? 'none': "block",
      };
    },
    mainTitle() {
      let { titleAlign, titleBold, titleColor, titleSize, oneGroupImageLeft } = this.receiveProps.tempData.specialStyle;
      return {
        "--main-font-color": titleColor,
        "--main-font-size": `${titleSize || 18}px`,
        "--main-font-weight": titleBold? '700': "",
        "--main-text-align": titleAlign,
        padding: !this.publishContent.includeItemsImageOrVideo&&Number(oneGroupImageLeft)?'10px 10px 0':
        this.publishContent.includeItemsImageOrVideo&&Number(oneGroupImageLeft)?'0 10px 0': '',
        
        marginTop: !this.publishContent.includeItemsImageOrVideo? '0': '16px'
      };
    },
    descriptionTitle() {
      let { descSize, descColor, descBold, descAlign, oneGroupImageLeft } = this.receiveProps.tempData.specialStyle;
      return {
        "--desp-font-color": descColor,
        "--desp-font-size": `${descSize || 14}px`,
        "--desp-font-weight": descBold? '700': '',
        "--desp-text-align": descAlign,
        margin: Number(oneGroupImageLeft)? '0 10px 10px': '',
        marginBottom: !this.publishContent.includeItemsImageOrVideo? '0': '8px'
      };
    },
    publishStyl(){
      let { oneGroupImageLeft } = this.receiveProps.tempData.specialStyle;
      return {
          padding: Number(oneGroupImageLeft)? '8px 10px 10px': '',
          paddingTop: !this.publishContent.includeItemsImageOrVideo?'8px': ''
      }
    },
    imageStyl() {
      let {imgBorder, imgRadius, imgShadow, imgBackgroundColor, oneGroupImageLeft} = this.receiveProps.tempData.specialStyle;
      return {
        "--image-border": imgBorder? "1px solid #D6DEEA": '',
        "--image-border-radius": imgRadius? "4px": '',
        "--image-box-shadow": imgShadow? "0px 0px 8px 0px rgba(0, 0, 0, 0.1)": '',
        "--image-background-color": imgBackgroundColor,
        "--image-width": this.isBanner?Number(oneGroupImageLeft)?"270px": '238px':Number(oneGroupImageLeft)?"264px": '232px',
        "--image-height": this.isBanner?Number(oneGroupImageLeft)?"202px":'178px':Number(oneGroupImageLeft)?"198px":'174px',
      };
    },
    articleBoxStyl() {
      // 判断上下图文   还是左图右文
      let { oneGroupBorder, oneGroupBGColor, oneGroupImageLeft, oneGroupBorderShadow, oneGroupBorderRadius } = this.receiveProps.tempData.specialStyle;
      return {
        "--article-box-border": oneGroupBorder? "1px solid #d6deea" : '',
        "--article-box-background-color": oneGroupBGColor,
        "--article-box-padding": !Number(oneGroupImageLeft)? "16px": '', // 判断 普通状态下'16px' 当贴边的时候为''
        "--article-box-shadow": oneGroupBorderShadow?"0px 0px 16px 0px rgba(0, 0, 0, 0.1)": '', 
        "--article-box-border-radius": oneGroupBorderRadius? "4px": '', // 判断是否是圆角
        "--article-box-hover": this.showPointer ? "pointer" : "",
        "--article-box-width": this.isBanner? '270px': '264px',
        "--article-box-height": this.isBanner? '336px': '335px'
      };
    },
    swiperBoxStyl(){
      let { oneGroupBorder, oneGroupBGColor, oneGroupImageLeft, oneGroupBorderShadow, oneGroupBorderRadius } = this.receiveProps.tempData.specialStyle;
      return {
        "--swiper-box-border": oneGroupBorder? "1px solid #d6deea" : '',
        "--swiper-box-background-color": oneGroupBGColor,
        "--swiper-box-padding": !Number(oneGroupImageLeft)? "10px": '', // 判断 普通状态下'16px' 当贴边的时候为''
        "--swiper-box-shadow": oneGroupBorderShadow?"0px 0px 16px 0px rgba(0, 0, 0, 0.1)": '', 
        "--swiper-box-border-radius": oneGroupBorderRadius? "4px": '', // 判断是否是圆角
        "--swiper-box-hover": this.showPointer ? "pointer" : "",
      };
    },
    articleInnerStyl(){
      return {
        "--grid-template-column": this.isBanner? '1fr 1fr 1fr 1fr': '1fr 1fr'
      }
    }
  },
  data() {
    return {
      showPointer: false,
      showContentType: '1',
      articleDataList: [],
      publishContent: this.receiveProps.tempData.specialData,
      buttonContent: this.receiveProps.tempData.buttonData,
      isBanner: true,
      showImgUrl: '',
      showMainTile: '',
      showMainDesc: '',
      isFileType: ''
    };
  },
  mounted() {
    getData(this, 3)
    setTimeout(() => {
      this.initSwiper();
    }, 1000);
    // ref获取数值  保持父级元素唯一性
    let classArr = this.$refs.articleContainerFour.parentNode.parentNode.classList
    for(var i=0;i<classArr.length;i++){ //获取父节点  el-col-24  el-col-12
        if(classArr[i]=='el-col-12'){
          this.isBanner = false
        } 
      }
  },
  methods: {
    initSwiper() {
      this.swiper = new Swiper(".swiper-container", {
        slidesPerView: 4,
        spaceBetween: 24,
        autoplay: true,
        // loop: true,
        // observer:true,//修改swiper自己或子元素时，自动初始化swiper 
        // observeParents:true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    skipLinks(item) {
      commonClickFn(item, this.publishContent, this)
    },
    checkMore() {
       checkMoreClickFn(this.buttonContent, this.publishContent, this)
    },
    overArticleItem(item){
      // 直接调取有手指  自由设置看是否有链接
      let {showContentType} = this.receiveProps.tempData.specialData
      let {selectType} = item
      if(showContentType != 2){ 
      if(selectType){
        this.showPointer = true
        return 
      }
         this.showPointer = false
      } else {
        this.showPointer = true
      } 
    },
  },
};
</script>

<style lang="less" scoped>
.articleTB-four {
  width: var(--all-box-width);
  margin: 0 auto;
  padding: 24px;
  box-sizing: border-box;
  margin-top: var(--all-margin-top);
  margin-bottom: var(--all-margin-bottom);
  background-color: var(--all-background-color);
  .link-more, .link-more-bottom{
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .more-box-top {
    padding: 0 24px 24px;
    text-align: var(--link-text-align);
    display: var(--link-top-display);
  }
  .more-box-bottom {
    display: var(--link-bottom-display);
    padding: 24px 24px 25px;
    text-align: var(--link-text-align);
  }
  .article-inner {
    display: grid;
    grid-template-columns: var(--grid-template-column);
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    .article-box {
      display: flex;
      width: var(--article-box-width);
      flex-direction: column;
      box-sizing: border-box;
      align-items: center;
      border: var(--article-box-border);
      background-color: var(--article-box-background-color);
      padding: var(--article-box-padding);
      border-radius: var(--article-box-border-radius);
      box-shadow: var(--article-box-shadow);
      height: fit-content;
      .img {
        border: var(--image-border);
        border-radius: var(--image-border-radius);
        box-shadow: var(--image-box-shadow);
        background-color: var(--image-background-color);
        font-size:0;
        position: relative;
        img {
          border-radius: var(--image-border-radius);
          width: 100%;
          height: 100%;
  
        }
        
        .img-default{
          width: 100%;
          height: 100%;
        }
      }
      .video-overly{
          width: 48px;
          height: 48px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          img{
            width: 100%;
            height: 100%;
          }
        }
      
    }
    .article-box:hover{
      cursor: var(--article-box-hover);
    }
  }
  .swiper-wrap {
    // display: none;
    width: 100%;
    position: relative;
    margin: auto;
    .swiper-slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 270px;
      border: var(--swiper-box-border);
      background-color: var(--swiper-box-background-color);
      padding: var(--swiper-box-padding);
      border-radius: var(--swiper-box-border-radius);
      box-shadow: var(--swiper-box-shadow);
      box-sizing: border-box;
      .inner-swiper {
        .image-swiper {
          border: var(--image-border);
          border-radius: var(--image-border-radius);
          box-shadow: var(--image-box-shadow);
          background-color: var(--image-background-color);
          font-size: 0;
          position: relative;
          
        }
        .video-overly{
          width: 48px;
          height: 48px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
      .inner-swiper:hover{
        cursor: var(--article-box-hover);
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .button-box {
      .swiper-button-next {
        background-image: none;
        width: 24px;
        height: 48px;
        position: absolute;
        top: 37%;
        right: 0;
      }
      .swiper-button-prev {
        background-image: none;
        width: 24px;
        height: 48px;
        position: absolute;
        top: 37%;
        left: 0;
      }
      img{
        width: 100%;
        height: 100%;
      }
      /deep/ .swiper-button-disabled{
        pointer-events: auto!important; // 阻止swiper事件到达第一张 左右切换穿透
    }
    }
  }
  // 公用样式
  .article {
        // flex: 1;
        width: 100%;
        .main-title {
          color: var(--main-font-color);
          font-size: var(--main-font-size);
          font-weight: var(--main-font-weight);
          text-align: var(--main-text-align);
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          margin-bottom: 8px;
          // margin-top: 16px;
        }
        .description {
          color: var(--desp-font-color);
          font-size: var(--desp-font-size);
          font-weight: var(--desp-font-weight);
          text-align: var(--desp-text-align);
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          word-break: break-all;
          // margin-bottom: 8px;
        }
        .publish-num{
          display: flex;
          align-items: center;
        }
        .publish-time {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
          margin-right: 24px;
        }
        .obser-num {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          display: inline-flex;
          align-items: center;
          img{
            margin-right: 8px;
          }
        }
      }
}
</style>
